import { Component, OnInit, inject } from '@angular/core'
import { Router, RouterOutlet } from '@angular/router'
import { BackToTopComponent } from '@components/back-to-top/back-to-top.component'
import { CustomizerComponent } from '@components/customizer/customizer.component'
import { PreloaderComponent } from '@components/preloader/preloader.component'
import { AuthUserDto } from '@core/models'
import { SecurityService } from '@core/services/security.service'
import { TitleService } from '@core/services/title.service'
import { ToastService } from '@core/services/toast.service'
import { LocalStorageHelper } from '@helpers/localstorage.helper'
import { ToastComponent } from './tone-zone/components/toast/toast.component'
import { SocketService } from '@core/services/socket.service'

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [
    RouterOutlet,
    PreloaderComponent,
    BackToTopComponent,
    CustomizerComponent,
    ToastComponent,
  ],
  templateUrl: './app.component.html',
})
export class AppComponent implements OnInit {
  titleService = inject(TitleService)
  securityService = inject(SecurityService)
  toaster = inject(ToastService)
  router = inject(Router)
  socketService = inject(SocketService)

  currentUser = new AuthUserDto();
  constructor(){
    onkeydown = onkeydown = (event) => {
      if (event.ctrlKey && event.key === '2') {
        event.stopPropagation();
        event.preventDefault();
        const appToken = this.securityService.securityObject?.token;
        if (appToken) {
          navigator.clipboard.writeText('Bearer ' + appToken);
          this.toaster.success('Token copied');
        }
      }
      if (event.ctrlKey && event.altKey && event.key === '3') {
        event.stopPropagation();
        event.preventDefault();
        const hasSwitchLocal = localStorage.getItem('switchLocal') ? true : false;
        if (hasSwitchLocal) {
          localStorage.removeItem('switchLocal');
          this.toaster.success('Live environment enabled');
        } else {
          localStorage.setItem('switchLocal', 'true');
          this.toaster.success('Local environment enabled');
        }
        location.reload();
      }
    };
  }
  ngOnInit(): void {
    this.titleService.init();
    this.verifyUserIsLoggedIn();
  }
  verifyUserIsLoggedIn(){
    var tempUser = LocalStorageHelper.getItem<AuthUserDto>('userData');
    if (tempUser) {
      this.securityService.securityObject = tempUser
      this.currentUser = this.securityService.securityObject;
      const isExpired = this.securityService.isAccessTokenExpired();
      if (isExpired) {
        this.securityService.logOut();
        // this.router.navigate(['/sign-in'])
        this.toaster.warning("Session expired!");
        return;
      }
      this.securityService.isSignIn.next(null);
      this.securityService.securityObject = tempUser


      // this.socketService.InitSocket();

    } else {
      // this.router.navigate(['/login']);
    }
    this.socketService.InitSocket();
  }
}
