import { Component, inject } from '@angular/core';
import { ToastService } from '@core/services/toast.service';
import { NgbToastModule } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-toast',
  standalone: true,
  imports: [NgbToastModule],
  template: `
  @for (toast of toastService.toasts; track $index) {

    <ngb-toast [header]="toast.header"
      [autohide]="true"
      [delay]="toast.delay"
      [class]="toast.classname"
      (hide)="toastService.remove(toast)">
      {{ toast.body }}
    </ngb-toast>
  }
  `,
  host: { class: 'toast-container position-fixed top-0 end-0 p-3', style: 'z-index: 1200' },
  // styles: [`
  //   :host {
  //     position: fixed;
  //     top: 1rem;
  //     right: 1rem;
  //     z-index: 1200;
  //   }
  // `]
})
export class ToastComponent {
  toastService = inject(ToastService)
}
