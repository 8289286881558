import {
  ApplicationConfig,
  importProvidersFrom,
  isDevMode,
} from '@angular/core'
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'
import {
  provideRouter,
  withInMemoryScrolling,
  type InMemoryScrollingFeature,
  type InMemoryScrollingOptions,
} from '@angular/router'

import {
  HTTP_INTERCEPTORS,
  HttpClientModule,
  provideHttpClient
} from '@angular/common/http'
import { provideEffects } from '@ngrx/effects'
import { provideStore } from '@ngrx/store'
import { provideLottieOptions } from 'ngx-lottie'
import { routes } from './app.routes'

import { IMAGE_CONFIG } from '@angular/common'
import { environment } from '@environment/environment'
import { ErrorInterceptor } from '@helpers/error.interceptor'
import { JwtInterceptor } from '@helpers/jwt.interceptor'
import { provideStoreDevtools } from '@ngrx/store-devtools'
import { AuthenticationEffects } from '@store/authentication/authentication.effects'
import { BillEffects } from '@store/billing/billing-effect'
import { provideCharts, withDefaultRegisterables } from 'ng2-charts'
import { provideEnvironmentNgxMask } from 'ngx-mask'
import { provideNgxStripe } from 'ngx-stripe'
import { rootReducer } from './store'

// Scroll
const scrollConfig: InMemoryScrollingOptions = {
  scrollPositionRestoration: 'top',
  anchorScrolling: 'enabled',
}

const inMemoryScrollingFeature: InMemoryScrollingFeature =
  withInMemoryScrolling(scrollConfig)

export const appConfig: ApplicationConfig = {
  providers: [
    provideCharts(withDefaultRegisterables()),
    provideLottieOptions({
      player: () => import('lottie-web'),
    }),
    provideRouter(routes, inMemoryScrollingFeature),
    provideStore(rootReducer),
    provideEffects(AuthenticationEffects, BillEffects),
    provideStoreDevtools({ maxAge: 25, logOnly: !isDevMode() }),
    provideHttpClient(), //provideHttpClient(withFetch())
    importProvidersFrom(HttpClientModule, BrowserAnimationsModule),
    provideEnvironmentNgxMask(),
    provideNgxStripe(environment.stripeKey),
    // FakeBackendProvider,
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    {
      provide: IMAGE_CONFIG,
      useValue: {
        disableImageSizeWarning: true,
        disableImageLazyLoadWarning: true,
      },
    },
  ],
}
