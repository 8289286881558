export class LocalStorageHelper {

  constructor() { }

  // Save data to local storage
  static setItem<T>(key: string, value: T): void {
    const jsonData = JSON.stringify(value);
    localStorage.setItem(key, jsonData);
  }

  // Retrieve data from local storage
  static getItem<T>(key: string): T | null {
    const jsonData = localStorage.getItem(key);
    if (jsonData) {
      return JSON.parse(jsonData) as T;
    }
    return null;
  }

  // Remove data from local storage
  static removeItem(key: string): void {
    localStorage.removeItem(key);
  }

  // Clear all data from local storage
  static clear(): void {
    localStorage.clear();
  }
}
