import { Injectable } from '@angular/core';
import { SetupProfileDto } from '@core/models/setup-profile.model';
import { AppConstants } from '@helpers/app-constants';
import { LocalStorageHelper } from '@helpers/localstorage.helper';
import moment from 'moment';

@Injectable({
  providedIn: 'root'
})
export class SetupProfileService {
  pendingProfiles: SetupProfileDto[] = []
  constructor() { }


  SavePendingProfile(profile: SetupProfileDto) {
    let pendingProfiles = LocalStorageHelper.getItem<SetupProfileDto[]>("pendingProfiles") || []
    var planExists = false;

    const index = pendingProfiles.findIndex(x => x.treatmentPlanCode === profile.treatmentPlanCode);
    if (index !== -1) {
      // Replace the existing profile with the new one
      pendingProfiles[index] = profile;
      planExists = true;
    }

    if (!planExists) {
      pendingProfiles.push(profile);
    }

    LocalStorageHelper.setItem('pendingProfiles', pendingProfiles)
  }
  GetAllPendingProfileByCode() {
    this.pendingProfiles = LocalStorageHelper.getItem("pendingProfiles") || []
    this.pendingProfiles.forEach(profile => {

      const startTime = moment(profile?.timeStamp, AppConstants.dateTimeFormat);
      const endTime = moment();

      const differenceInMinutes = endTime.diff(startTime, 'minutes');

      if (differenceInMinutes > 30) {
          this.RemoveExpiredProfile(profile.treatmentPlanCode)
      }
    })

    return this.pendingProfiles;
  }
  GetPendingProfileByCode(treatmentPlanCode: string) {
    this.pendingProfiles = LocalStorageHelper.getItem("pendingProfiles") || []
    let existingProfile = this.pendingProfiles.find(x => x.treatmentPlanCode == treatmentPlanCode);
    const startTime = moment(existingProfile?.timeStamp, AppConstants.dateTimeFormat);
    const endTime = moment();

    const differenceInMinutes = endTime.diff(startTime, 'minutes');

    if (differenceInMinutes > 30) {
        this.RemoveExpiredProfile(treatmentPlanCode)
        existingProfile = undefined;
    }
    return existingProfile;
  }

  RemoveExpiredProfile(treatmentPlanCode: string) {
    this.pendingProfiles = LocalStorageHelper.getItem("pendingProfiles") || []
    this.pendingProfiles = this.pendingProfiles.filter(x => x.treatmentPlanCode != treatmentPlanCode)
    LocalStorageHelper.setItem('pendingProfiles', this.pendingProfiles)
  }
  getCountdownTime(timeStamp: string): string {
    const startTime = new Date(timeStamp);
    const endTime = new Date(startTime.getTime() + 30 * 60 * 1000);

    let diffMs = endTime.getTime() - new Date().getTime();
    let diffSec = Math.floor(diffMs / 1000);
    let minutes = Math.floor(diffSec / 60);
    let seconds = diffSec % 60;

    return `${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
  }
}
