<main class="page-wrapper">
  <component-navigation-bar />

  <div class="container py-5 mt-4 mt-lg-5 mb-lg-4 my-xl-5">
    <div class="row pt-sm-2 pt-lg-0">
      <aside class="col-lg-2 pe-lg-4 pe-xl-5 mt-n3">
        <div class="position-lg-sticky top-0">
          <div class="d-none d-lg-block" style="padding-top: 50px;"></div>
          <div class="offcanvas-start offcanvas-lg" id="sidebarAccount">
            <button
              class="btn-close position-absolute top-0 end-0 mt-3 me-3 d-lg-none"
              type="button"
              data-bs-dismiss="offcanvas"
              data-bs-target="#sidebarAccount"
              aria-label="Close"
            ></button>
            <div class="offcanvas-body">
              <div class="pb-2 pb-lg-0 mb-4 mb-lg-5">
                <img *ngIf="currentUser.profileImagePath"
                  class="d-block rounded-circle mb-2"
                  [src]="appConstants.awsBaseUrl+currentUser.profileImagePath"
                  width="80"
                  height="80"
                  style="height: 80px;min-height: 80px;min-width: 80px;object-fit: cover;"
                  alt="Isabella Bocouse"
                />
                <!-- <img
                  class="d-block rounded-circle mb-2"
                  src="../../../../assets/img/custom/user-
                  icon.png"
                  width="80"
                  alt="Isabella Bocouse"
                /> -->
                <div *ngIf="!currentUser.profileImagePath" class="d-flex align-items-center text-capitalize justify-content-center position-relative flex-shrink-0 rounded-circle text-primary font-22px fw-bold my-1" style="width: 80px; height: 80px; background-color: rgba(var(--ar-primary-rgb), 0.15);">
                  {{currentUser.fullName  | shortCode}}
                </div>
                <h3 class="h5 mb-1 font-18px">{{currentUser.fullName}}</h3>
                <p class="fs-sm text-body-secondary mb-0 my-text-ellp-135" title="{{currentUser.email}}">
                  {{currentUser.email}}
                </p>
              </div>
              @for (item of account; track $index; let last = $last) {
                <nav *hasClaim="item.claims"
                  class="nav flex-column pb-2 pb-lg-4 "
                  [ngClass]="last ? 'mb-1' : 'mb-3'"
                >
                  @if (item.items) {
                    <h4
                      class="fs-xs fw-medium text-body-secondary text-uppercase pb-1 mb-2"
                    >
                      {{ item.label }}
                    </h4>
                  }
                  @for (data of item.items; track $index) {
                    <a *hasClaim="data.claims"
                      class="nav-link fw-semibold py-2 px-0"
                      routerLink="{{ data.link }}"
                      routerLinkActive="active"

                    >
                      <i class="{{ data.icon }} fs-5 opacity-60 me-2"></i>
                      {{ data.label }}
                      @if (data.badge && totalUnreadCountOfChats > 0) {
                        <span class="badge bg-danger ms-auto">{{
                          totalUnreadCountOfChats
                        }}</span>
                      }
                    </a>
                  }
                  @if (!item.items) {
                    <a *hasClaim="item.claims"
                      class="nav-link fw-semibold py-2 px-0"
                      routerLink="{{ item.link }}"
                    >
                      <i class="{{ item.icon }} fs-5 opacity-60 me-2"></i>
                      {{ item.label }}
                    </a>
                  }
                </nav>
              }
              <nav class="nav flex-column" style="cursor: pointer;">
                <a
                  class="nav-link fw-semibold py-2 px-0"
                  (click)="signOut()"
                >
                  <i class="ai-logout fs-5 opacity-60 me-2"></i>
                  Sign out
                </a>
              </nav>
            </div>
          </div>
        </div>
      </aside>

      <div class="col-lg-10 pt-4 pb-2 pb-sm-4">
        @if (pendingProfiles?.length) {
          @for (profile of pendingProfiles; track $index) {
            <div class="alert alert-primary d-flex align-items-center mb-3" role="alert">
              <i class="ai-circle-info " style="font-size: 26px;"> </i>
              <div>
                <h6 class="h6 m-0 ps-2 alert-link">Pending Subscription</h6>
                <div class="ps-2">
                  🎉 Almost there! Your <strong>{{profile?.treatmentPlan?.displayName}}</strong> is pending. Click <a
                  class="alert-link ms-1"
                  routerLink="/customer/state" [queryParams]="{_tp: profile.treatmentPlanCode}"
                  >Proceed!</a
                > to continue.
                </div>
              </div>
              <i style="cursor: pointer;" class="ai-trash fs-xl ms-auto text-danger" (click)="RemovePlan(profile);"></i>
            </div>
          }
        }
        <router-outlet />
      </div>
    </div>
  </div>
  <hr class="d-none d-dark-mode-block" />
  <button
    class="d-none btn btn-sm fs-sm btn-primary w-100 rounded-0 fixed-bottom"
    type="button"
    data-bs-toggle="offcanvas"
    data-bs-target="#sidebarAccount"
    (click)="openOffcanvas(content)"
  >
    <i class="ai-menu me-2"></i>
    Account menu
  </button>
</main>

<app-tz-footer />
<ng-template
  #content
  let-offcanvas
  class="offcanvas-start"
  id="sidebarAccount"
>
  <button
    class="btn-close position-absolute top-0 end-0 mt-3 me-3 d-lg-none"
    type="button"
    data-bs-dismiss="offcanvas"
    data-bs-target="#sidebarAccount"
    aria-label="Close"
    (click)="offcanvas.close('close click')"
  ></button>
  <div class="offcanvas-body">
    <div class="pb-2 pb-lg-0 mb-4 mb-lg-5">
      <img
        class="d-block rounded-circle mb-2"
        src="assets/img/avatar/02.jpg"
        width="80"
        alt="Isabella Bocouse"
      />
      <h3 class="h5 mb-1">Isabella Bocouse</h3>
      <p class="fs-sm text-body-secondary mb-0">bocouse&#64;example.com</p>
    </div>
    @for (item of account; track $index; let last = $last) {
      <nav *hasClaim="item.claims"
        class="nav flex-column pb-2 pb-lg-4 "
        [ngClass]="last ? 'mb-1' : 'mb-3'"
      >
        @if (item.items) {
          <h4
            class="fs-xs fw-medium text-body-secondary text-uppercase pb-1 mb-2"
          >
            {{ item.label }}
          </h4>
        }
        @for (data of item.items; track $index) {
          <a *hasClaim="data.claims" (click)="offcanvas.close('Close Click')"
            class="nav-link fw-semibold py-2 px-0 abcd1"
            routerLink="{{ data.link }}"
            routerLinkActive="active"
          >
            <i class="{{ data.icon }} fs-5 opacity-60 me-2"></i>
            {{ data.label }}
            @if (data.badge) {
              <span class="badge bg-danger ms-auto">{{ data.badge }}</span>
            }
          </a>
        }
        @if (!item.items) {
          <a *hasClaim="item.claims" (click)="offcanvas.close('Close Click')"
            class="nav-link fw-semibold py-2 px-0 abcd2"
            routerLink="{{ item.link }}"
          >
            <i class="{{ item.icon }} fs-5 opacity-60 me-2"></i>
            {{ item.label }}
          </a>
        }
      </nav>
    }
    <nav class="nav flex-column">
      <a class="nav-link fw-semibold py-2 px-0" routerLink="/login">
        <i class="ai-logout fs-5 opacity-60 me-2"></i>
        Sign out
      </a>
    </nav>
  </div>
</ng-template>
