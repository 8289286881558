<header class="navbar navbar-expand-lg fixed-top">
  <div class="container">
    <component-logo-box [className]="'pe-sm-3'" />

    <app-theme-switcher
      class="form-check form-switch mode-switch order-lg-2 me-3 me-lg-4 ms-auto"
    />

    <div class="dropdown nav d-none d-sm-block order-lg-3">
      <a
        class="nav-link d-flex align-items-center p-0"
        href="javascript:void(0);"
        data-bs-toggle="dropdown"
        aria-expanded="false"
      >
        <img
          class="border rounded-circle"
          src="assets/img/avatar/01.jpg"
          width="48"
          alt="Isabella Bocouse"
        />
        <div class="ps-2">
          <div class="fs-xs lh-1 opacity-60">Hello,</div>
          <div class="fs-sm dropdown-toggle">Isabella</div>
        </div>
      </a>
      <div class="dropdown-menu dropdown-menu-end my-1">
        <h6
          class="dropdown-header fs-xs fw-medium text-body-secondary text-uppercase pb-1"
        >
          Account
        </h6>
        <a class="dropdown-item" routerLink="/account/overview">
          <i class="ai-user-check fs-lg opacity-70 me-2"></i>
          Overview
        </a>
        <a class="dropdown-item" routerLink="/account/settings">
          <i class="ai-settings fs-lg opacity-70 me-2"></i>
          Settings
        </a>
        <a class="dropdown-item" routerLink="/account/billing">
          <i class="ai-wallet fs-base opacity-70 me-2 mt-n1"></i>
          Billing
        </a>
        <div class="dropdown-divider"></div>
        <h6
          class="dropdown-header fs-xs fw-medium text-body-secondary text-uppercase pb-1"
        >
          Dashboard
        </h6>
        <a class="dropdown-item" routerLink="/account/orders">
          <i class="ai-cart fs-lg opacity-70 me-2"></i>
          Orders
        </a>
        <a class="dropdown-item" routerLink="/account/earnings">
          <i class="ai-activity fs-lg opacity-70 me-2"></i>
          Earnings
        </a>
        <a
          class="dropdown-item d-flex align-items-center"
          routerLink="/account/chat"
        >
          <i class="ai-messages fs-lg opacity-70 me-2"></i>
          Chat
          <span class="badge bg-danger ms-auto">4</span>
        </a>
        <a class="dropdown-item" routerLink="/account/favorites">
          <i class="ai-heart fs-lg opacity-70 me-2"></i>
          Favorites
        </a>
        <div class="dropdown-divider"></div>
        <a class="dropdown-item" routerLink="/auth/sign-in">
          <i class="ai-logout fs-lg opacity-70 me-2"></i>
          Sign out
        </a>
      </div>
    </div>

    <vertical-menu-button></vertical-menu-button>

    <nav
      class="collapse navbar-collapse"
      #collapse="ngbCollapse"
      [(ngbCollapse)]="!isMenuCollapsed"
      id="navbarNav"
    >
      <horizontal-app-menu />
    </nav>
  </div>
</header>
