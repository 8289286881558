import {
  Component,
  ElementRef,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
  Renderer2,
  inject,
} from '@angular/core'
import { LogoBoxComponent } from '@components/logo-box/logo-box.component'
import { NgbCollapseModule } from '@ng-bootstrap/ng-bootstrap'
import { buyLink } from 'src/app/states/constants'
import { HorizontalAppMenu } from 'src/app/tone-zone/components/app-menu/horizontal-app-menu/horizontal-app-menu.component'
import { VerticalMenuButtonComponent } from 'src/app/tone-zone/components/app-menu/vertical-menu-button.component'
import { ThemeSwitcherComponent } from '../components/theme-switcher/theme-switcher.component'
import { RouterModule } from '@angular/router'
import { SecurityService } from '@core/services/security.service'
import { AuthUserDto } from '@core/models'
import { CommonModule } from '@angular/common'
import { AppConstants } from '@helpers/app-constants'
import { UserType } from '@core/enums/user.enum'
import { ShortCodePipe } from '@core/pipes/short-code.pipe'
import { ChatService } from '@core/services/chat.service'
import { PersonalChatGroupDto } from '@core/models/chat.model'
import { SocketService } from '@core/services/socket.service'

@Component({
  selector: 'component-navigation-bar',
  standalone: true,
  imports: [
    HorizontalAppMenu,
    LogoBoxComponent,
    ThemeSwitcherComponent,
    NgbCollapseModule,
    VerticalMenuButtonComponent,
    RouterModule,
    CommonModule,
    ShortCodePipe,
  ],
  templateUrl: './navigation-bar.component.html',
})
export class NavigationBarComponent implements OnInit, OnDestroy {
  @Input() mode?: 'light' | 'dark'
  securityService = inject(SecurityService)
  socketService = inject(SocketService)
  chatService = inject(ChatService)
  private render = inject(Renderer2)

  currentUser = new AuthUserDto()
  isMenuCollapsed = false
  buyLink = buyLink
  appConstants = AppConstants
  userTypeEnum = UserType
  chatGroupsList: PersonalChatGroupDto[] = []
  gettingChatGroups!: boolean
  totalUnreadCountOfChats = 0
  private chatSubscription: any
  private audio: HTMLAudioElement

  constructor(private elementRef: ElementRef) {
    this.audio = new Audio('assets/sounds/chat-sound.mp3')
  }

  ngOnInit(): void {
    if (this.mode) {
      this.render.setAttribute(
        this.elementRef.nativeElement,
        'data-bs-theme',
        this.mode
      )
    }
    if (this.securityService.securityObject.id) {
      this.currentUser = this.securityService.securityObject
      this.GetPersonalChatGroupById()
    }
    this.subscribeEvents()
  }
  ngOnDestroy(): void {
    // Unsubscribe from chat message listener
    if (this.chatSubscription) {
      this.chatSubscription.unsubscribe()
    }
  }
  subscribeEvents() {
    this.chatSubscription = this.socketService.OnChatMessageReceived(
      (chatData) => {
        if(!chatData.isMuted){
          this.playChatRingtone();
        }
        this.totalUnreadCountOfChats = this.totalUnreadCountOfChats + 1
      }
    )
    this.chatService.resetUnreadCount.subscribe((chatGroup: any) => {
      if (this.chatGroupsList.length) {
        const hasChatGroup = this.chatGroupsList.some(
          (ch) => ch.id == chatGroup.id
        )
        if (hasChatGroup) {
          this.totalUnreadCountOfChats = 0
        }
      }
    })
  }
  // Window Scroll Event
  @HostListener('window:scroll', ['$event'])
  onWindowScroll() {
    this.checkScroll()
  }

  checkScroll() {
    const scrollTop =
      window.scrollY ||
      document.documentElement.scrollTop ||
      document.body.scrollTop ||
      0

    if (scrollTop > 20) {
      this.elementRef.nativeElement
        .querySelector('.navbar')
        ?.classList.add('navbar-stuck')
    } else {
      this.elementRef.nativeElement
        .querySelector('.navbar')
        ?.classList.remove('navbar-stuck')
    }
  }
  signOut() {
    this.securityService.logOut()
  }
  GetPersonalChatGroupById() {
    this.gettingChatGroups = true
    this.chatService
      .GetPersonalChatGroupById(this.securityService.securityObject.appUserId)
      .subscribe({
        next: (res: PersonalChatGroupDto[]) => {
          this.totalUnreadCountOfChats = 0
          if (res?.length) {
            res.sort((a, b) => {
              return +new Date(b.lastMessageTime) - +new Date(a.lastMessageTime)
            })
            res.forEach((cg) => {
              this.totalUnreadCountOfChats =
                cg.unreadMsgCount + this.totalUnreadCountOfChats
            })
          }
          this.chatGroupsList = structuredClone(res)
          this.gettingChatGroups = false
          // this.EmitUnReadCount()
        },
        error: (err) => {
          this.gettingChatGroups = false
        },
      })
  }
  playChatRingtone() {
    // Play the audio file
    this.audio
      .play()
      .then(() => {
        console.log('Audio playing')
      })
      .catch((error) => {
        console.error('Error playing audio:', error)
      })
  }
}
