import { Component } from '@angular/core';
import { RouterModule } from '@angular/router';
import { createdBy, developedByLink } from 'src/app/states/constants'

@Component({
  selector: 'app-tz-footer',
  standalone: true,
  imports: [RouterModule],
  templateUrl: './tz-footer.component.html',
  styleUrl: './tz-footer.component.scss'
})
export class TzFooterComponent {

  author = createdBy
  developBy = developedByLink
  company = [{name:'Services', url: '/services'},{name:'Blogs', url: '/blogs'},]
  support = [{name:'Terms of service', url: '/terms-service'}, {name:'Privacy policy', url: '/privacy-policy'}, {name:'Contact', url: '/contacts'}, {name:'Returns & Refunds', url: '/refunds'}]
}
