import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { filter, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class EventBusService {
  private subject = new Subject<any>();

  constructor() {}

  on(event: EventTypes): Observable<any> {
    return this.subject
      .pipe(
        filter((e: EmitEvent) => {
          return e.name === event;
        }),
        map((e: EmitEvent) => {
          return e.value;
        })
      );
  }

  emit(event: EmitEvent) {
    this.subject.next(event);
  }
}

export class EmitEvent {
  name: any;
  value: any;
}

export enum EventTypes {
  ChatReadEvent = 1,
  UpdateUnReadChatCount = 2,
  RatingAdded = 3,
  OpenSideMenu = 4,
}
