import { Routes } from '@angular/router'
import { AuthGuard } from '@core/guards'
import { AccountLayoutComponent } from './layouts/account-layout.component'
import { DashboardLayoutComponent } from './tone-zone/dashboard/dashboard-layout/dashboard-layout.component'
import { TzComingSoonComponent } from './tone-zone/components/tz-coming-soon/tz-coming-soon.component'

export const routes: Routes = [
  {
    path: 'theme',
    canActivate: [AuthGuard],
    loadComponent: () =>
      import('./pages/landings/home/home.component').then(
        (mod) => mod.HomeComponent
      ),
    title: 'Around Angular | Multipurpose Bootstrap Template',
  },
  {
    path: '', redirectTo: 'home', pathMatch: 'full'
  },
  {
    path: '',
    loadChildren: () =>
      import('./tone-zone/tone-zone.route').then(
        (mod) => mod.TONEZONE_ROUTES
      ),
  },
  {
    path: 'app',
    component: DashboardLayoutComponent,
    loadChildren: () =>
      import('./tone-zone/dashboard/dashboard.route').then(
        (mod) => mod.DASHBOARD_ROUTES
      ),
  },
  {
    path: 'customer',
    // component: DashboardLayoutComponent,
    loadChildren: () =>
      import('./tone-zone/customer-sign-up/customer-sign-up.route').then(
        (mod) => mod.CUSTOMER_SIGNUP_ROUTES
      ),
  },
  {
    path: 'landings',
    loadChildren: () =>
      import('./pages/landings/landings.route').then(
        (mod) => mod.LANDING_ROUTES
      ),
  },
  {
    path: 'appointment',
    loadChildren: () =>
      import('../app/tone-zone/meets/meets.module').then((m) => m.MeetsModule),
  },
  {
    path: 'portfolio',
    loadChildren: () =>
      import('./pages/portfolio/portfolio.route').then(
        (mod) => mod.PORTFOLIO_ROUTES
      ),
  },
  {
    path: 'shop',
    loadChildren: () =>
      import('./pages/shop/shop.route').then((mod) => mod.SHOP_ROUTES),
  },
  {
    path: 'blog',
    loadChildren: () =>
      import('./pages/blog/blog.route').then((mod) => mod.BLOG_ROUTES),
  },
  {
    path: 'auth',
    loadChildren: () =>
      import('./pages/account/auth/auth.route').then((mod) => mod.AUTH_ROUTES),
  },
  {
    path: 'about',
    loadChildren: () =>
      import('./pages/about/about.route').then((mod) => mod.ABOUT_ROUTE),
  },
  {
    path: 'services',
    loadChildren: () =>
      import('./pages/services/service.route').then(
        (mod) => mod.SERVICE_ROUTES
      ),
  },
  {
    path: 'contacts',
    loadChildren: () =>
      import('./pages/contacts/contact.route').then((mod) => mod.CONTACT_ROUTE),
  },
  {
    path: 'pages/pricing',
    loadComponent: () =>
      import('./pages/pricing/pricing.component').then(
        (mod) => mod.PricingComponent
      ),
    data: { title: 'pricing' },
  },
  {
    path: 'pages',
    loadChildren: () =>
      import('./pages/pages/pages.route').then((mod) => mod.PAGES_ROUTES),
  },
  {
    path: 'account',
    component: AccountLayoutComponent,
    loadChildren: () =>
      import('./pages/account/pages/account.route').then(
        (mod) => mod.ACCOUNT_ROUTES
      ),
  },

]
