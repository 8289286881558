<nav class="collapse navbar-collapse" id="navbarNav">
  <ul
    class="navbar-nav navbar-nav-scroll me-auto"
    style="--ar-scroll-height: 520px">
    <li class="dropdown nav-item">
      <a href="javascript:void(0);" data-bs-toggle="dropdown" data-bs-auto-close="outside" aria-expanded="false" class="dropdown-toggle nav-link"  routerLinkActive="active">
      Treatment
      </a>
      <ul class="dropdown-menu">
        <li><a  routerLinkActive="active" routerLink="/weight"  class="dropdown-item"><strong>Weight Management Program</strong></a></li>
        <li><a  routerLinkActive="active"  href="javascript:void(0);" title="Coming Soon"  class="dropdown-item"><strong>Testosterone Therapy</strong></a></li>
        <li><a  routerLinkActive="active"  href="javascript:void(0);" title="Coming Soon"  class="dropdown-item"><strong>Insomnia Therapy</strong></a></li>
        <li><a  routerLinkActive="active"  href="javascript:void(0);" title="Coming Soon"  class="dropdown-item"><strong>Erectile Dysfunction Therapy</strong></a></li>
        <!-- <li>
          <a routerLink="/weight"  routerLinkActive="router-link-active"  class="dropdown-item" target="_self">Compounded Semaglutide Injectable</a>
        </li>
        <li>
          <a href="javascript:void(0);" class="dropdown-item" target="_self"> Compounded Semaglutide Drops </a>
        </li>
        <li>
          <a href="javascript:void(0);" class="dropdown-item" target="_self"> Compounded Semaglutide Tablets   </a>
        </li>
        <li>
          <a href="javascript:void(0);" class="dropdown-item" target="_self"> Compounded Tirzapetide  </a>
        </li>
        <li>
          <a href="javascript:void(0);" class="dropdown-item" target="_self"> Compounded Tirzapetide Drops  </a>
        </li>
        <li>
          <a href="javascript:void(0);" class="dropdown-item" target="_self"> Compounded Liraglutide </a>
        </li>
        <li>
          <a href="javascript:void(0);" class="dropdown-item" target="_self"> Naltrexone-Bupropion Tablets   </a>
        </li>
        <li>
          <a href="javascript:void(0);" class="dropdown-item" target="_self">Orlistat</a>
        </li> -->
      </ul>
    </li>
   
    <!-- <li class="nav-item">
      <a
        class="nav-link"  routerLinkActive="active"
        [routerLink]="'/weight'"
        > Weight Management Program </a>
    </li> -->
    <li class="nav-item">
      <a
        class="nav-link"  routerLinkActive="active"
        [routerLink]="'/blogs'"
        >Blogs</a>
    </li>
    <li class="nav-item">
      <a
        class="nav-link"  routerLinkActive="active"
        [routerLink]="'/contacts'"
        >Contact</a>
    </li>
    <li class="dropdown nav-item">
      <a href="javascript:void(0);" data-bs-toggle="dropdown" data-bs-auto-close="outside" aria-expanded="false" class="dropdown-toggle nav-link">
      FAQ
      </a>
      <ul class="dropdown-menu">
        <!-- <li>
          <a  [routerLink]="['/simple-access']" class="dropdown-item" routerLinkActive="router-link-active" target="_self"> Simple Access</a>
        </li> -->
        <li>
          <a  [routerLink]="['/values']" class="dropdown-item" target="_self"> Values </a>
        </li>
        <li>
          <a  [routerLink]="['/provider']" class="dropdown-item" target="_self"> Providers </a>
        </li>
      </ul>
    </li>
    @if (!currentUser?.id) {
      <li class="nav-item">
        <a
          class="nav-link"  routerLinkActive="active"
          [routerLink]="'/login'"
          >Login</a>
      </li>
    }
    @if (currentUser?.id) {
      <li class="nav-item" style="cursor: pointer;">
        @if (currentUser.userType != userTypeEnum.Customer) {
          <a
            class="nav-link"  routerLinkActive="active"
            (click)="GotoDashboard()"
            >Dashboard</a>
        } @else {
          <a
            class="nav-link"  routerLinkActive="active"
            routerLink="/app/customer/overview"
            >Overview</a>
        }
      </li>
    }
  </ul>
  <div class="d-sm-none p-3 mt-n3">
    <a
      class="btn btn-primary w-100 mb-1"
      :href="buyLink"
      target="_blank"
      rel="noopener"
    >
      <i class="ai-cart fs-xl me-2 ms-n1"></i>
      Get Started
    </a>
  </div>
</nav>

<ng-template #megaMenuItem let-item="item" let-imageClassName="imageClassName">
  <li>
    <ng-container
      *ngTemplateOutlet="
        menuItemLink;
        context: {
          item,
          linkClassName:
            'dropdown-item' +
            (activeMenuItems.includes(item.key) ? ' active' : '')
        }
      "
    />
    <span
      [ngClass]="[
        'mega-dropdown-column position-absolute top-0 end-0 h-100 bg-size-cover bg-repeat-0 ',
        imageClassName
      ]"
      [style.background-image]="'url(' + item.image + ')'"
    ></span>
  </li>
</ng-template>

<ng-template
  #menuItemWithChildren
  let-item="item"
  let-className="className"
  let-linkClassName="linkClassName"
  let-childLinkClassName="childLinkClassName"
>
  <li class="{{ className }}">
    <a
      class="{{ linkClassName }}"
      href="javascript:void(0);"
      data-bs-toggle="dropdown"
      data-bs-auto-close="outside"
      aria-expanded="false"
      >{{ item.label }}</a
    >
    <ul class="dropdown-menu">
      @for (child of item.children; track child.key) {
        @if (child.children) {
          <ng-container
            *ngTemplateOutlet="
              menuItemWithChildren;
              context: {
                item: child,
                className: 'dropdown',
                linkClassName:
                  childLinkClassName +
                  (activeMenuItems.includes(child.key) ? ' active' : '')
              }
            "
          />
        } @else {
          <ng-container
            *ngTemplateOutlet="
              menuItem;
              context: {
                item: child,
                linkClassName: activeMenuItems.includes(child.key)
                  ? ' active'
                  : ''
              }
            "
          />
        }
      }
    </ul>
  </li>
</ng-template>

<ng-template
  #menuItem
  let-item="item"
  let-className="className"
  let-linkClassName="linkClassName"
  let-activeMenuItems="activeMenuItems"
>
  <li [ngClass]="className">
    <ng-container
      *ngTemplateOutlet="
        menuItemLink;
        context: {
          item,
          linkClassName: 'dropdown-item ' + linkClassName
        }
      "
    />
  </li>
</ng-template>

<ng-template #menuItemLink let-item="item" let-className="linkClassName">
  <a
    [ngClass]="className"
    [routerLink]="item.url"
    [target]="item.target ?? '_self'"
  >
    {{ item.label }}
  </a>
</ng-template>
