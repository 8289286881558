<footer
  class="footer bg-dark position-relative pb-4 pt-md-3 py-lg-4 py-xl-5 pb-5 mb-4 mb-lg-0"
>
  <div
    class="d-none d-dark-mode-block position-absolute top-0 start-0 w-100 h-100"
    style="background-color: rgba(255, 255, 255, 0.03)"
  ></div>
  <div class="container position-relative z-2 pt-5 pb-2" data-bs-theme="dark">
    <div class="row" id="links">
      @for (item of LinkData; track $index) {
        @if (item.name == 'Showroom') {
          <div class="col-md-3 pb-2 pb-md-0">
            <h3 class="h6 text-uppercase d-none d-md-block">{{ item.name }}</h3>
            <a
              class="btn-more h6 mb-1 text-uppercase text-decoration-none d-flex align-items-center d-md-none"
              (click)="toggleMenu(item)"
              [ngClass]="{ collapsed: item.isCollapsed == true }"
              [attr.aria-expanded]="item.isCollapsed"
              [attr.data-show-label]="item.name"
              [attr.data-hide-label]="item.name"
            ></a>
            <div
              [ngbCollapse]="item.isCollapsed"
              class="collapse d-md-block"
              id="showroom"
              data-bs-parent="#links"
            >
              <ul class="nav flex-column pb-3">
                <li class="nav-item">
                  <a
                    class="nav-link fw-normal px-0 py-1"
                    href="javascript:void(0);"
                    >{{ item.street1 }}<br />{{ item.street2 }}</a
                  >
                </li>
                <li class="nav-item">
                  <a
                    class="nav-link fw-normal px-0 py-1"
                    href="mailto:email@example.com"
                    >{{ item.email }}</a
                  >
                </li>
                <li class="nav-item">
                  <a class="nav-link fw-normal px-0 py-1" href="+15262200459">{{
                    item.contact
                  }}</a>
                </li>
              </ul>
              <ul class="list-unstyled mb-0 pb-3 pb-md-0">
                @for (time of item.time; track $index) {
                  <li class="text-light opacity-90 mb-2">{{ time }}</li>
                }
              </ul>
            </div>
          </div>
        } @else {
          <div class="col-md-3 col-xl-2 pb-2 pb-md-0">
            <h3 class="h6 text-uppercase d-none d-md-block">{{ item.name }}</h3>
            <a
              class="btn-more h6 mb-1 text-uppercase text-decoration-none d-flex align-items-center d-md-none"
              (click)="toggleMenu(item)"
              [ngClass]="{ collapsed: item.isCollapsed == true }"
              [attr.aria-expanded]="item.isCollapsed"
              [attr.data-show-label]="item.name"
              [attr.data-hide-label]="item.name"
            ></a>
            <div
              [ngbCollapse]="item.isCollapsed"
              class="collapse d-md-block"
              id="useful"
              data-bs-parent="#links"
            >
              <ul class="nav flex-column pb-2 pb-md-0">
                @for (sublink of item.sublinks; track $index) {
                  <li class="nav-item">
                    <a
                      class="nav-link fw-normal px-0 py-1"
                      href="javascript:void(0);"
                      >{{ sublink }}</a
                    >
                  </li>
                }
              </ul>
            </div>
          </div>
        }
      }

      <div class="col-12 col-xl-3 mt-md-2 mt-xl-0 pt-2 pt-md-4 pt-xl-0">
        <h3 class="h6 text-uppercase mb-1 pb-1">Download our app</h3>
        <div class="d-flex d-xl-block d-xxl-flex">
          <a
            class="btn btn-secondary px-3 py-2 mt-3 me-3"
            href="javascript:void(0);"
          >
            <img
              class="mx-1"
              src="assets/img/market/appstore-light.svg"
              width="120"
              alt="App Store"
            />
          </a>
          <a
            class="btn btn-secondary px-3 py-2 mt-3"
            href="javascript:void(0);"
          >
            <img
              class="mx-1"
              src="assets/img/market/googleplay-light.svg"
              width="119"
              alt="Google Play"
            />
          </a>
        </div>
      </div>
    </div>

    <div
      class="d-sm-flex align-items-end justify-content-between border-bottom mt-2 mt-sm-1 pt-4 pt-sm-5"
    >
      <nav class="nav d-flex mb-3 mb-sm-4">
        <a
          class="nav-link text-body-secondary fs-sm fw-normal ps-0 pe-2 py-2 me-4"
          href="javascript:void(0);"
          >Support</a
        >
        <a
          class="nav-link text-body-secondary fs-sm fw-normal ps-0 pe-2 py-2 me-4"
          href="javascript:void(0);"
          >Privacy</a
        >
        <a
          class="nav-link text-body-secondary fs-sm fw-normal ps-0 pe-2 py-2 me-sm-4"
          href="javascript:void(0);"
          >Terms of use</a
        >
      </nav>

      <div class="dropdown mb-4" [autoClose]="false" ngbDropdown>
        <button
          class="btn btn-outline-secondary dropdown-toggle px-4"
          type="button"
          data-bs-toggle="dropdown"
          data-bs-auto-close="outside"
          aria-expanded="false"
          ngbDropdownToggle
        >
          <img
            class="me-2"
            src="assets/img/flags/en.png"
            width="18"
            alt="English / USD"
          />Eng / USD
        </button>
        <div class="dropdown-menu dropdown-menu-end my-1" ngbDropdownMenu>
          <div class="dropdown-item">
            <select class="form-select form-select-sm">
              <option value="usd">{{ selectedCurrency }} USD</option>
              <option value="eur">€ EUR</option>
              <option value="ukp">£ UKP</option>
              <option value="jpy">¥ JPY</option>
            </select>
          </div>
          <a class="dropdown-item pb-1" href="javascript:void(0);">
            <img
              class="me-2"
              src="assets/img/flags/fr.png"
              width="18"
              alt="Français"
            />
            Français
          </a>
          <a class="dropdown-item pb-1" href="javascript:void(0);">
            <img
              class="me-2"
              src="assets/img/flags/de.png"
              width="18"
              alt="Deutsch"
            />
            Deutsch
          </a>
          <a class="dropdown-item" href="javascript:void(0);">
            <img
              class="me-2"
              src="assets/img/flags/it.png"
              width="18"
              alt="Italiano"
            />
            Italiano
          </a>
        </div>
      </div>
    </div>

    <div class="d-sm-flex align-items-center pt-4">
      <div class="d-sm-flex align-items-center pe-sm-2">
        <a
          class="navbar-brand d-inline-flex align-items-center me-sm-5 mb-4 mb-sm-0"
          routerLink="/"
        >
          <span class="text-primary flex-shrink-0 me-2">
            <svg
              version="1.1"
              width="35"
              height="32"
              viewBox="0 0 36 33"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill="currentColor"
                d="M35.6,29c-1.1,3.4-5.4,4.4-7.9,1.9c-2.3-2.2-6.1-3.7-9.4-3.7c-3.1,0-7.5,1.8-10,4.1c-2.2,2-5.8,1.5-7.3-1.1c-1-1.8-1.2-4.1,0-6.2l0.6-1.1l0,0c0.6-0.7,4.4-5.2,12.5-5.7c0.5,1.8,2,3.1,3.9,3.1c2.2,0,4.1-1.9,4.1-4.2s-1.8-4.2-4.1-4.2c-2,0-3.6,1.4-4,3.3H7.7c-0.8,0-1.3-0.9-0.9-1.6l5.6-9.8c2.5-4.5,8.8-4.5,11.3,0L35.1,24C36,25.7,36.1,27.5,35.6,29z"
              ></path>
            </svg>
          </span>
          <span class="text-light opacity-90">Around</span>
        </a>
        <div class="d-flex mb-4 mb-sm-0">
          <a
            class="btn btn-icon btn-sm btn-secondary btn-telegram rounded-circle mx-2 ms-sm-0 me-sm-3"
            href="javascript:void(0);"
            aria-label="Telegram"
          >
            <i class="ai-telegram"></i>
          </a>
          <a
            class="btn btn-icon btn-sm btn-secondary btn-instagram rounded-circle mx-2 ms-sm-0 me-sm-3"
            href="javascript:void(0);"
            aria-label="Instagram"
          >
            <i class="ai-instagram"></i>
          </a>
          <a
            class="btn btn-icon btn-sm btn-secondary btn-facebook rounded-circle mx-2 ms-sm-0 me-sm-3"
            href="javascript:void(0);"
            aria-label="Facebook"
          >
            <i class="ai-facebook"></i>
          </a>
          <a
            class="btn btn-icon btn-sm btn-secondary btn-pinterest rounded-circle mx-2 ms-sm-0 me-sm-3"
            href="javascript:void(0);"
            aria-label="Piterest"
          >
            <i class="ai-pinterest"></i>
          </a>
        </div>
      </div>
      <img
        class="ms-sm-auto"
        src="assets/img/shop/footer-cards.png"
        width="187"
        alt="Accepted cards"
      />
    </div>
  </div>
</footer>
