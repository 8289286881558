type AccountItem = {
  label: string
  icon?: string
  link: string
  badge?: string
  claims?: string[]
}

export type AccountMenuType = {
  label: string
  icon?: string
  items?: AccountItem[]
  link?: string
  claims?: string[]
}
export const accountData: AccountMenuType[] = [
  {
    label: 'Admin',
    claims: ["UserType:AppAdmin"],
    items: [
      {
        label: 'Dashboard',
        icon: 'ai-dashboard',
        link: '/app/admin/dashboard',
        claims: ["UserType:AppAdmin"],
      },
      {
        label: 'Subscriptions',
        icon: 'ai-dollar',
        link: '/app/subscriptions',
        claims: ["UserType:AppAdmin"],
      },
      {
        label: 'Orders',
        icon: 'ai-box',
        link: '/app/admin/orders',
        claims: ["UserType:AppAdmin"],
      },
      {
        label: 'Pharmacies',
        icon: 'ai-shopping-bag',
        link: '/app/admin/pharmacies',
        claims: ["UserType:AppAdmin"],
      },
      {
        label: 'Customers',
        icon: 'ai-user-group',
        link: '/app/admin/customers',
        claims: ["UserType:AppAdmin"],
      },
      {
        label: 'Providers',
        icon: 'ai-user-group',
        link: '/app/admin/providers',
        claims: ["UserType:AppAdmin"],
      },
      {
        label: 'Coupons',
        icon: 'ai-wallet',
        link: '/app/admin/coupons',
        claims: ["UserType:AppAdmin"],
      },
    ],
  },
  {
    label: 'Provider',
    claims: ["UserType:Provider"],
    items: [
      {
        label: 'Dashboard',
        icon: 'ai-dashboard',
        link: '/app/provider/dashboard',
        claims: ["UserType:Provider"],
      },

      {
        label: 'Profile',
        icon: 'ai-user',
        link: '/app/profile',
        claims: ["UserType:Provider"],
      },
      // {
      //   label: 'Calendar',
      //   icon: 'ai-calendar-check',
      //   link: '/app/calendar',
      //   claims: ["UserType:Provider"],
      // },
      {
        label: 'Appointments',
        icon: 'ai-calendar-check',
        link: '/app/appointments',
        claims: ["UserType:Provider"],
      },
      {
        label: 'Customers',
        icon: 'ai-user-group',
        link: '/app/provider/customers',
        claims: ["UserType:Provider"],
      },
      {
        label: 'Scheduler',
        icon: 'ai-time',
        link: '/app/provider/availability',
        claims: ["UserType:Provider"],
      },
      {
        label: 'Messages',
        icon: 'ai-messages',
        link: '/app/chat',
        badge: '0',
        claims: ["UserType:Provider"],
      },
      // {
      //   label: 'States',
      //   icon: 'ai-map-pin',
      //   link: '/app/provider/states',
      //   claims: ["UserType:Provider"],
      // }
    ],
  },
  {
    label: 'Customer',
    claims: ["UserType:Customer"],
    items: [
      {
        label: 'Overview',
        icon: 'ai-dashboard',
        link: '/app/customer/overview',
        claims: ["UserType:Customer"],
      },
      {
        label: 'Profile',
        icon: 'ai-user',
        link: '/app/profile',
        claims: ["UserType:Customer"],
      },
      {
        label: 'Health Summary',
        icon: 'ai-file-text',
        link: '/app/summary',
        claims: ["UserType:Customer"],
      },
      {
        label: 'Appointments',
        icon: 'ai-calendar-check',
        link: '/app/appointments',
        claims: ["UserType:Customer"],
      },
      {
        label: 'Messages',
        icon: 'ai-messages',
        link: '/app/chat',
        badge: '0',
        claims: ["UserType:Customer"],
      },
      // {
      //   label: 'Billing',
      //   icon: 'ai-wallet',
      //   link: '/app/billing',
      //   claims: ["UserType:Customer"],
      // },
      {
        label: 'Subscriptions',
        icon: 'ai-dollar',
        link: '/app/subscriptions',
        claims: ["UserType:Customer"],
      },
    ],
  },
  // {
  //   label: 'Dashboard',
  //   items: [
  //     {
  //       label: 'Orders',
  //       icon: 'ai-cart',
  //       link: '/account/orders',
  //     },
  //     {
  //       label: 'Earnings',
  //       icon: 'ai-activity',
  //       link: '/account/earnings',
  //     },
  //     {
  //       label: 'Chat',
  //       icon: 'ai-messages',
  //       link: '/account/chat',
  //       badge: '0',
  //     },
  //     {
  //       label: 'Favorites',
  //       icon: 'ai-heart',
  //       link: '/account/favorites',
  //     },
  //   ],
  // },

]
