<div
  role="button"
  class="btn-scroll-top"
  data-scroll
  aria-label="Scroll back to top"
  (click)="scrollToTop()"
>
  <svg
    viewBox="0 0 40 40"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle
      cx="20"
      cy="20"
      r="19"
      fill="none"
      stroke="currentColor"
      stroke-width="1.5"
      stroke-miterlimit="10"
    ></circle>
  </svg>
  <i class="ai-arrow-up"></i>
</div>
