import { HttpBackend, HttpClient, HttpHeaders } from '@angular/common/http'
import { Injectable, inject } from '@angular/core'
import { Router } from '@angular/router'
import { AppUserClaim } from '@core/models/app-user-claims.model'
import {
  AuthUserDto,
  ChangePasswordDto,
  ForgotPassword,
  Register,
  ResetPassword,
  UserSignIn,
  VerifyResetPasswordCode,
} from '@core/models/user.model'
import { environment } from '@environment/environment'
import moment from 'moment'
import { Subject } from 'rxjs'
import { ToastService } from './toast.service'

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json',
  }),
}
@Injectable({
  providedIn: 'root',
})
export class SecurityService {
  toaster = inject(ToastService)
  http = inject(HttpClient)
  router = inject(Router)
  isSignIn = new Subject()
  isSignOut = new Subject()
  baseUrl = environment.baseUrl
  securityObject = new AuthUserDto()
  private http2: HttpClient;
  constructor(
    private handler: HttpBackend
  ) {
    this.http2 = new HttpClient(handler);
  }
  Register(registerObj: Register) {
    return this.http.post(
      `${this.baseUrl}UserAccount/Register`,
      registerObj,
      httpOptions
    )
  }
  GetTimeZonesByCountryCode(countryCode: string) {
    return this.http.get(
      `${this.baseUrl}Common/GetTimeZonesByCountryCode?countryCode=${countryCode}`,
      httpOptions
    )
  }
  GetTimeZonesByCountryId(countryId: number) {
    return this.http.get(
      `${this.baseUrl}Common/GetTimeZonesByCountryId/${countryId}`,
      httpOptions
    )
  }

  SignIn(Signin: UserSignIn) {
    return this.http.post<AuthUserDto>(
      `${this.baseUrl}UserAccount/token`,
      Signin,
      httpOptions
    )
  }
  hasClaim(claimType: any, claimValue?: any) {
    let ret = false
    // See if an array of values was passed in.
    if (typeof claimType === 'string') {
      ret = this.isClaimValid(claimType, claimValue)
    } else {
      const claims: string[] = claimType
      if (claims?.length) {
        for (let index = 0; index < claims.length; index++) {
          ret = this.isClaimValid(claims[index])
          // If one is successful, then let them in
          if (ret) {
            break
          }
        }
      } else {
        ret = true
      }
    }

    return ret
  }

  private isClaimValid(claimType: string, claimValue?: string): boolean {
    let ret = false
    let auth!: AuthUserDto

    // Retrieve security object
    auth = this.securityObject
    if (auth) {
      // See if the claim type has a value
      // *hasClaim="'claimType:value'"
      if (claimType.indexOf(':') >= 0) {
        const words: string[] = claimType.split(':')
        claimType = words[0].toLowerCase()
        claimValue = words[1]
      } else {
        claimType = claimType.toLowerCase()
        // Either get the claim value, or assume 'true'
        claimValue = claimValue ? claimValue : 'true'
      }
      // Attempt to find the claim
      ret =
        auth.claims.find(
          (c) =>
            c.claimType.toLowerCase() === claimType &&
            c.claimValue === claimValue
        ) != null
    }

    return ret
  }

  getClaim(claimType: string, claimValue?: string) {
    let claim: AppUserClaim | undefined
    let auth: AuthUserDto

    // Retrieve security object
    auth = this.securityObject
    if (auth) {
      // See if the claim type has a value
      // *hasClaim="'claimType:value'"
      if (claimType.indexOf(':') >= 0) {
        const words: string[] = claimType.split(':')
        claimType = words[0].toLowerCase()
        claimValue = words[1]
      } else {
        claimType = claimType.toLowerCase()
        // Either get the claim value, or assume 'true'
        claimValue = claimValue ? claimValue : 'true'
      }
      // Attempt to find the claim
      claim = auth.claims.find((c) => c.claimType.toLowerCase() === claimType)
      // claim = auth.claims.find(c => c.claimType.toLowerCase() === claimType && c.claimValue === claimValue);
    }

    return claim
  }

  updateClaim(claimType: string, claimValue: string): boolean {
    let auth: AuthUserDto
    auth = this.securityObject
    if (claimType && claimValue) {
      const claim = auth.claims.find(
        (c) => c.claimType.toLowerCase() === claimType
        // && c.claimValue === claimValue
      )
      if (claim) {
        claim.claimValue = claimValue
        return true
      }
      return false
    }
    return false
  }
  ForgotPassword(forgotPasswordDto: ForgotPassword) {
    return this.http.post(
      `${this.baseUrl}UserAccount/ForgotPassword`,
      forgotPasswordDto,
      httpOptions
    )
  }
  VerifyResetPasswordCode(verifyResetPasswordCodeDto: VerifyResetPasswordCode) {
    return this.http.post(
      `${this.baseUrl}UserAccount/VerifyResetPasswordCode`,
      verifyResetPasswordCodeDto,
      httpOptions
    )
  }
  ResetPassword(resetPasswordDto: ResetPassword) {
    return this.http.post(
      `${this.baseUrl}UserAccount/ResetPassword`,
      resetPasswordDto,
      httpOptions
    )
  }
  getPhoneNoAvailability(phoneNumber: string) {
    return this.http.get(
      `${this.baseUrl}UserAccount/PhoneNoAvailable?phoneNumber=${phoneNumber}`,
      httpOptions
    )
  }
  SendEmailVerificationCode(email: string) {
    return this.http.post(
      this.baseUrl + `UserAccount/SendEmailVerificationCode?email=${email}`,
      httpOptions
    )
  }
  isAccessTokenExpired(token?: string): boolean {
    if (this.isLoggedIn()) {
      const RefrExpMin = moment
        .utc(this.securityObject.tokenExpiration)
        .local()
        .diff(moment(), 'minutes')
      if (RefrExpMin < 2) {
        return true
      } else {
        return false
      }
      return true
    } else {
      return false
    }
  }
  isLoggedIn(): boolean {
    if (this.securityObject && this.securityObject.isAuthenticated === true) {
      return true
    } else {
      return false
    }
  }

  RemoveUserDevice(userId: string) {
    return this.http.post(
      this.baseUrl + `UserAccount/Logout?UserId=${userId}`,
      httpOptions
    )
  }
  logOut() {
    localStorage.removeItem('userData')
    this.securityObject = new AuthUserDto()
    // this.router.navigate(['/login']);
    const currentUrl = this.router.url
    if (currentUrl.includes('/app')) {
      this.router.navigate(['/login'])
    } else {
      window.location.reload()
    }
  }
  changePassword(changePasswordDto: ChangePasswordDto) {
    return this.http.post(
      `${this.baseUrl}UserAccount/ChangePassword`, changePasswordDto,
      httpOptions
    )
  }
  // VerifyOtp(verifyOtp: VerifyOtpDto, ){
  //   return this.http.post(this.baseUrl +`UserAccount/VerifyOtp`,verifyOtp,httpOptions).pipe(
  //     catchError(this.httpErrorService.handleHttpError)
  //   )
  // }
}
