export enum UserType {
  Provider = 1,
  Customer = 2,
  AppAdmin = 3,
}
export enum GenderType {
  Unknown = 0,
  Male = 1,
  Female = 2,
}
