import { CommonModule } from '@angular/common'
import { Component, inject, Input } from '@angular/core'
import { NgbCollapseModule, NgbOffcanvas } from '@ng-bootstrap/ng-bootstrap'
import type { MenuItemType } from 'src/app/common/menu-items'
import { VerticalMenuItemComponent } from './vertical-menu-item.component'
import { RouterModule } from '@angular/router'

@Component({
  selector: 'vertical-menu-item-with-children',
  standalone: true,
  imports: [CommonModule, VerticalMenuItemComponent, NgbCollapseModule, RouterModule, ],
  styles: `
    :host(vertical-menu-item-with-children) {
      display: contents;
    }
  `,
  template: `
  <li class="dropdown nav-item">
    <a
        data-bs-toggle="collapses"
        role="button"
        class="collapse-item nav-link px-0"
        href="javascript:void(0)"
        aria-haspopup="true"
        [attr.aria-expanded]="!isCollapsedFaq"
        (click)="isCollapsedFaq = !isCollapsedFaq"
      >
        FAQ
        <i class="dropdown-toggle ms-auto"></i>
      </a>
      <div
        [(ngbCollapse)]="isCollapsedFaq"
        #collapses="ngbCollapse"
        class="border-start"
      >
      
        <!-- data-bs-target="#shopSidebar"
        aria-label="Close"
         -->
      <ul class="nav d-flex flex-column">
        <!-- <li class="nav-item pt-1 px-3">
          <a (click)="offcanvasService.dismiss()" data-bs-dismiss="offcanvas" [routerLink]="['/simple-access']" class="nav-link pt-1 px-0" target="_self"> Simple Access </a>
        </li> -->
        <li class="nav-item pt-1 px-3">
          <a  routerLinkActive="active" (click)="offcanvasService.dismiss()" data-bs-dismiss="offcanvas" [routerLink]="['/values']" class="nav-link pt-1 px-0" target="_self"> Values </a>
        </li>
        <li class="nav-item pt-1 px-3">
          <a  routerLinkActive="active" (click)="offcanvasService.dismiss()" data-bs-dismiss="offcanvas" [routerLink]="['/provider']" class="nav-link pt-1 px-0" target="_self"> Providers </a>
        </li>
      </ul>
    </div>
  </li>
    <li class="dropdown nav-item">
    <a
        data-bs-toggle="collapse"
        role="button"
        class="collapse-item nav-link px-0"
        href="javascript:void(0)"
        aria-haspopup="true"
        [attr.aria-expanded]="!isCollapsed"
        (click)="isCollapsed = !isCollapsed"
      >
        Treatment
        <i class="dropdown-toggle ms-auto"></i>
      </a>
      <div
        [(ngbCollapse)]="isCollapsed"
        #collapse="ngbCollapse"
        class="border-start">
      <ul class="nav d-flex flex-column">
        <li class="nav-item pt-1 px-3">
            <a  routerLinkActive="active" data-bs-dismiss="offcanvas" (click)="offcanvasService.dismiss()"  routerLink="/weight"  class="nav-link pt-1 px-0" target="_self"><strong>Weight Management</strong></a>
        </li>
        <li class="nav-item pt-1 px-3">
            <a  routerLinkActive="active" href="javascript:void(0);"  data-bs-dismiss="offcanvas" (click)="offcanvasService.dismiss()" title="Coming Soon"  class="nav-link pt-1 px-0" target="_self"><strong>Testosterone Therapy</strong></a>
        </li>
        <li class="nav-item pt-1 px-3">
            <a  routerLinkActive="active" href="javascript:void(0);"  data-bs-dismiss="offcanvas" (click)="offcanvasService.dismiss()" title="Coming Soon"  class="nav-link pt-1 px-0" target="_self"><strong>Insomnia Therapy</strong></a>
        </li>
        <li class="nav-item pt-1 px-3">
            <a  routerLinkActive="active" href="javascript:void(0);"  data-bs-dismiss="offcanvas" (click)="offcanvasService.dismiss()" title="Coming Soon"  class="nav-link pt-1 px-0" target="_self"><strong>Erectile Dysfunction Therapy</strong></a>
        </li>
       
      </ul>
    </div>
    </li>
    <li class="nav-item">
      <a
        class="nav-link"  routerLinkActive="active"
       (click)="offcanvasService.dismiss()" data-bs-dismiss="offcanvas"   [routerLink]="'/blogs'"
        >Blogs</a>
    </li>
    <li class="nav-item">
      <a
        class="nav-link"  routerLinkActive="active"
       (click)="offcanvasService.dismiss()" data-bs-dismiss="offcanvas"   [routerLink]="'/contacts'"
        >Contact</a>
    </li>
    <li class="nav-item">
      <a
        class="nav-link"  routerLinkActive="active"
       (click)="offcanvasService.dismiss()" data-bs-dismiss="offcanvas" [routerLink]="'/login'"
        >Login</a>
    </li>
  `,
})
export class VerticalMenuItemWithChildrenComponent {
  offcanvasService = inject(NgbOffcanvas)
  @Input() item!: MenuItemType
  @Input() activeMenuItems?: string[]
  @Input() itemClassName?: string
  @Input() linkClassName?: string
  isCollapsed = true
  isCollapsedFaq = true
  ngOnInit() {}

  getActiveClass(item: MenuItemType) {
    return this.activeMenuItems?.includes(item.key) ? ' show' : ''
  }
}
