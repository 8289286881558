import { environment } from "@environment/environment";

export const AppConstants = {
  dateFormat: "MM/DD/YYYY",
  timeFormat: "hh:mm A",
  dateTimeFormat: "MM/DD/YYYY hh:mm A",
  displayDateFormat: "MMM dd, yyyy",
  fullCalendar: {
    dateFormat: "YYYY-MM-DD",
    dateTimeFormat: "YYYY-MM-DDTHH:mm",
  },
  awsBaseUrl: `https://${environment.bucketAws}.s3.amazonaws.com/`
}
