import { CommonModule } from '@angular/common'
import { Component, inject } from '@angular/core'
import { Router, RouterModule } from '@angular/router'
import { UserType } from '@core/enums/user.enum'
import { AuthUserDto } from '@core/models'
import { SecurityService } from '@core/services/security.service'
import { findAllParent, getMenuItemFromURL, getMenuItems } from '@helpers/menu'
import { MenuItemType } from 'src/app/common/menu-items'
import { basePath, buyLink } from 'src/app/states/constants'
import { splitArray } from 'src/app/utils/array'

@Component({
  selector: 'horizontal-app-menu',
  standalone: true,
  imports: [RouterModule, CommonModule],
  templateUrl: './horizontal-app-menu.component.html',
})
export class HorizontalAppMenu {
  securityService = inject(SecurityService)
  router = inject(Router)
  menuItems = getMenuItems()
  buyLink = buyLink

  megaMenuItems = this.menuItems.filter((item) => item.isMega)
  normalMenuItems = this.menuItems.filter((item) => !item.isMega)
  splitMegaMenuItems: MenuItemType[][] = splitArray(
    this.megaMenuItems[0].children ?? [],
    10
  )

  trimmedURL = location?.pathname?.replaceAll(
    basePath !== '' ? basePath + '/' : '',
    '/'
  )
  matchingMenuItem = getMenuItemFromURL(this.menuItems, this.trimmedURL)

  activeMenuItems: string[] = []
  currentUser!: AuthUserDto
  userTypeEnum = UserType

  ngOnInit() {
    if (this.matchingMenuItem) {
      this.activeMenuItems = [
        ...findAllParent(this.menuItems, this.matchingMenuItem),
      ]
    }
    if(this.securityService.securityObject.id){
      this.currentUser = this.securityService.securityObject;
    }
  }
  GotoDashboard() {
    if(this.currentUser.userType == UserType.AppAdmin){
      this.router.navigateByUrl('/app/admin/dashboard');
    }
    if(this.currentUser.userType == UserType.Provider){
      this.router.navigateByUrl('/app/provider/dashboard');
    }
  }
}
