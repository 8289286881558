import { Injectable } from '@angular/core';

export class Toast {
  header: string = "";
  body: string = "";
  delay: number = 4000;
  classname: string = "";
}

@Injectable({
  providedIn: 'root'
})
export class ToastService {
  toasts: Toast[] = [];

  show(header: string, body: string, delay = 4000, classname = 'bg-success') {
    this.toasts.push({ header, body, delay, classname });
  }
  success(body: string, header = 'Success', delay = 4000) {
    this.show(header, body, delay, 'bg-success text-light');
  }

  warning(body: string, header = 'Warning', delay = 4000) {
    this.show(header, body, delay, 'bg-warning text-dark');
  }

  error(body: string, header = 'Error', delay = 4000) {
    this.show(header || "Something went wrong", body, delay, 'bg-danger text-light');
  }


  remove(toast: Toast) {
    this.toasts = this.toasts.filter(t => t !== toast);
  }
}
