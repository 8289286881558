import { Injectable } from '@angular/core';
import { ChatDto, PublishChatViewedDto } from '@core/models/chat.model';
import { environment } from '@environment/environment';
import * as signalR from '@microsoft/signalr';
import { BehaviorSubject } from 'rxjs';
import { SignalData } from 'simple-peer';
import { SecurityService } from './security.service';

@Injectable({
  providedIn: 'root'
})
export class SocketService {
  connection!: signalR.HubConnection;
  private _isConnected: boolean = false;

  get isConnected(): boolean {
      return this._isConnected;
  }

  set isConnected(value: boolean) {
      this._isConnected = value;
      // You can add additional logic here if needed
      console.info(`Signalr ${value == true ? "connected" : "dis-connected"} ${this.connection.connectionId}`)
      this.socketStateSubject.next(value);
  }
  socketStateSubject = new BehaviorSubject<boolean>(this.isConnected);


  get socketId() {
    return this.connection.connectionId;
  }
  constructor(private securityService: SecurityService) { }


  async InitSocket() {
    this.connection = new signalR.HubConnectionBuilder()
    // "https://apichirphost.premiersolution.link/chatHub"
    .withUrl( environment.baseUrl.replace('api/','chatHub'))
    .withAutomaticReconnect([1000, 2000, 10000, 30000])
    .build();
    await this.connection.start().then(() => {
      this.isConnected = true;
      this.subscribeSignalRMessages();
    })
    this.connection.onclose(async (err) => {
      this.isConnected = false
      await this.connection.start()
      this.subscribeSignalRMessages();
    });
    this.connection.onreconnecting((err) => {
      this.isConnected = false
      console.info("Re connecting", err)
    });
    this.connection.onreconnected((err) => {
      this.isConnected = true
      this.subscribeSignalRMessages();
    });
  }
  subscribeSignalRMessages() {
    this.connection.invoke('JoinGroup', `${this.securityService.securityObject.appUserId}-ChatChannel`);
    this.connection.invoke('JoinGroup', `${this.securityService.securityObject.appUserId}-PushNotification`);
  }
  async TerminateConnection() {
    return await this.connection.stop()
  }

  listen(eventName: string, fn: Function) {
    this.connection.on(eventName, fn as any);

    // Return an object to unsubscribe
    return {
      unsubscribe: () => {
        this.connection.off(eventName, fn as any);
      }
    };
  }
  off(channel: string) {
    this.connection.off(channel)
  }

  private send(chanel: string, message: SignalMessage) {
    this.connection.invoke(chanel, message)
  }
  updateScheduleDuration(scheduleId: number){
    this.connection.invoke('UpdateScheduleDuration', scheduleId);
  }
  // onConnect(fn: () => void) {
  //   this.listen('connect', fn)
  // }
  OnLiveUsers(fn: (live_users: any) => void) {
    this.listen('OnLiveUsers', fn)
  }

  OnRoomJoined(fn: (participants: string, roomName: string) => void) {
    this.listen('OnRoomJoined', fn)
  }

  onRoomLeft(fn: (socketId:  string) => void) {
    this.listen('OnRoomLeft', fn)
  }
  OnDisconnected(fn: (socketId:  string) => void) {
    this.listen('OnDisconnected', fn)
  }
  OnRoomsData(fn: (roomObj: Object) => void) {
    this.listen('OnRoomsInfo', fn)
  }

  onAnswer(fn: (msg: SignalMessage) => void) {
    this.listen('OnAnswer', fn)
  }

  onOffer(fn: (msg: SignalMessage) => void) {
    this.listen('OnOffer', fn)
  }
  OnPublishToRoom(fn: (msg: Object) => void) {
    this.listen('OnPublishToRoom', fn)
  }
  OnChatMessageReceived(fn: (msg: ChatDto) => void) {
    return this.listen('OnChatMessageReceived', fn); // Return the subscription object
  }
  OnChatViewed(fn: (msg: PublishChatViewedDto) => void) {
    return this.listen('OnChatViewed', fn); // Return the subscription object
  }
  RoomJoinRequest(roomName: string, name: string, isHost = false, profileImagePath: any) {
    // this.send('room_join_request', msg)
    this.connection.invoke('RoomJoinRequest', roomName, name, isHost, profileImagePath)
  }
  LeaveRoom(roomName: string) {
    // this.send('room_join_request', msg)
    this.connection.invoke('LeaveRoom', roomName)
  }
  sendOfferSignal(msg: SignalMessage) {
    this.send('OfferSignal', msg)
  }

  sendAnswerSignal(msg: SignalMessage) {
    this.send('AnswerSignal', msg)
  }
  PublishToRoom(roomName: string, msg: Object) {
    this.connection.invoke('PublishToRoom', roomName ,msg )
  }
}

export interface SignalMessage {
  callerId?: string | null
  calleeId?: string,
  signalData?: SignalData,
  msg?: string,
  roomName?: string
}
