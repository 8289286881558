import { CommonModule } from '@angular/common'
import {
  Component,
  OnDestroy,
  OnInit,
  TemplateRef,
  ViewChild,
  inject,
} from '@angular/core'
import { RouterModule } from '@angular/router'
import { NavigationBarComponent } from '@components/navigation-bars'
import { HasClaimDirective } from '@core/directives/has-claim.directive'
import { UserType } from '@core/enums/user.enum'
import { AuthUserDto } from '@core/models'
import { PersonalChatGroupDto } from '@core/models/chat.model'
import { SetupProfileDto } from '@core/models/setup-profile.model'
import { ShortCodePipe } from '@core/pipes/short-code.pipe'
import { ChatService } from '@core/services/chat.service'
import { SecurityService } from '@core/services/security.service'
import { SetupProfileService } from '@core/services/setup-profile.service'
import { SocketService } from '@core/services/socket.service'
import { AppConstants } from '@helpers/app-constants'
import { EventBusService, EventTypes } from '@helpers/event-bus.service'
import { NgbOffcanvas, NgbOffcanvasModule } from '@ng-bootstrap/ng-bootstrap'
import {
  AccountMenuType,
  accountData,
} from 'src/app/pages/account/pages/menu-sidebar'
import { currency } from 'src/app/states/constants'
import { toggleDocumentAttribute } from 'src/app/utils/layout'
import { TzFooterComponent } from '../../components/tz-footer/tz-footer.component'
import { TzHeaderComponent } from '../../components/tz-header/tz-header.component'
@Component({
  selector: 'app-dashboard-layout',
  standalone: true,
  imports: [
    NavigationBarComponent,
    TzHeaderComponent,
    RouterModule,
    CommonModule,
    NgbOffcanvasModule,
    TzFooterComponent,
    HasClaimDirective,
    ShortCodePipe,
  ],
  templateUrl: './dashboard-layout.component.html',
  styleUrl: './dashboard-layout.component.scss',
})
export class DashboardLayoutComponent implements OnInit, OnDestroy {
  @ViewChild('content', { static: true }) offcanvasContent!: TemplateRef<any>

  securityService = inject(SecurityService)
  socketService = inject(SocketService)
  private offcanvasService = inject(NgbOffcanvas)
  chatService = inject(ChatService)
  setupProfileService = inject(SetupProfileService);

  chatGroupsList: PersonalChatGroupDto[] = []
  account: AccountMenuType[] = accountData
  selectedCurrency = currency
  currentUser = new AuthUserDto()
  appConstants = AppConstants
  UserType = UserType
  gettingChatGroups!: boolean
  totalUnreadCountOfChats = 0
  private chatSubscription: any
  pendingProfiles!: SetupProfileDto[]

  constructor(private eventBus: EventBusService) {}
  ngOnInit(): void {
    toggleDocumentAttribute('class', 'bg-secondary', 'body', false)
    if (this.securityService.securityObject.id) {
      this.currentUser = this.securityService.securityObject
    }
    this.GetPersonalChatGroupById()
    this.OnOpenSideMenu()
    this.subscribeEvents()
    this.pendingProfiles = this.setupProfileService.GetAllPendingProfileByCode()
  }
  RemovePlan(profile: SetupProfileDto) {
    this.setupProfileService.RemoveExpiredProfile(profile.treatmentPlanCode)
    this.pendingProfiles = this.setupProfileService.GetAllPendingProfileByCode()
  }
  subscribeEvents() {
    this.chatSubscription = this.socketService.OnChatMessageReceived(
      (chatData) => {
        this.totalUnreadCountOfChats = this.totalUnreadCountOfChats + 1
      }
    )
    this.chatService.resetUnreadCount.subscribe((chatGroup: any) => {
      if (this.chatGroupsList.length) {
        const hasChatGroup = this.chatGroupsList.some(
          (ch) => ch.id == chatGroup.id
        )
        if (hasChatGroup) {
          this.totalUnreadCountOfChats = 0
        }
      }
    })
  }
  ngOnDestroy(): void {
    toggleDocumentAttribute('class', 'bg-secondary', 'body', true)
    if (this.chatSubscription) {
      this.chatSubscription.unsubscribe()
    }
  }
  OnOpenSideMenu() {
    this.eventBus.on(EventTypes.OpenSideMenu).subscribe((data) => {
      this.openOffcanvas(this.offcanvasContent)
    })
  }
  openOffcanvas(content: TemplateRef<any>) {
    this.offcanvasService.open(content, { position: 'start' })
  }
  signOut() {
    this.securityService.logOut()
  }
  GetPersonalChatGroupById() {
    this.gettingChatGroups = true
    this.chatService
      .GetPersonalChatGroupById(this.securityService.securityObject.appUserId)
      .subscribe({
        next: (res: PersonalChatGroupDto[]) => {
          this.totalUnreadCountOfChats = 0
          if (res?.length) {
            res.sort((a, b) => {
              return +new Date(b.lastMessageTime) - +new Date(a.lastMessageTime)
            })
            res.forEach((cg) => {
              this.totalUnreadCountOfChats =
                cg.unreadMsgCount + this.totalUnreadCountOfChats
            })
          }
          this.chatGroupsList = structuredClone(res)
          this.gettingChatGroups = false
          // this.EmitUnReadCount()
        },
        error: (err) => {
          this.gettingChatGroups = false
        },
      })
  }
}
