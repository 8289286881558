import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http'
import { Injectable } from '@angular/core'
import { Observable, throwError } from 'rxjs'
import { catchError } from 'rxjs/operators'

import { ToastService } from '@core/services/toast.service'
import { AuthenticationEffects } from '@store/authentication/authentication.effects'

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  constructor(public autheffect: AuthenticationEffects, private toaster: ToastService) {}

  intercept(
    request: HttpRequest<Request>,
    next: HttpHandler
  ): Observable<HttpEvent<Event>> {

    return next.handle(request).pipe(
      catchError((err: HttpErrorResponse) => {
        if (err.status === 401) {
          // auto logout if 401 response returned from api
          // this.securityService.logOut()
          // window.location.reload()
        }

        let errorMessage = this.getErrorMessage(err)
        this.toaster.error(`${errorMessage || `Something went wrong`}`, err.statusText)
        return throwError(() => err);
      })
    )
  }
  getErrorMessage = (err: HttpErrorResponse) => {
    let errorString = '';
    if (typeof err.error === 'object') {
      for (const key in err.error) {
        if (err.error.hasOwnProperty(key)) {
          errorString += key + ' : ' + err.error[key];
        }
      }
      errorString = errorString;
    } else {
      errorString = err.error;
    }
    return errorString;
  }
}
