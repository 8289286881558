import { HttpClient, HttpHeaders } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { catchError } from 'rxjs/operators'
import { environment } from 'src/environments/environment'
import { SecurityService } from './security.service'
import { SocketService } from './socket.service'
import {
  AddUserToChatGroupDto,
  ChatChannelsDto,
  ChatDto,
  ChatHistoryDto,
  CreateChatGroupDto,
  MarkChatGroupMuteDto,
  MarkChatViewedDto,
  PersonalChatGroupDto,
  SearchParticipant,
  SendMessageDto,
} from '@core/models/chat.model'
import { Subject } from 'rxjs'

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json',
  }),
}

@Injectable({
  providedIn: 'root',
})
export class ChatService {
  baseUrl = environment.baseUrl
  informLocationsOfMessageReceived = new Subject()
  resetUnreadCount = new Subject()
  constructor(
    private http: HttpClient,
    private securityService: SecurityService,
    private socketService: SocketService
  ) {}
  SearchChatUsers(SearchText: string) {
    return this.http.get<SearchParticipant[]>(
      `${this.baseUrl}Chat/SearchChatUsers?SearchString=${SearchText}`
    )
  }
  GetPersonalChatGroup(senderId: string, recipientId: string) {
    return this.http.get<PersonalChatGroupDto>(
      `${this.baseUrl}Chat/GetPersonalChatGroup?senderUserId=${senderId}&recepientUserId=${recipientId}`
    )
  }
  GetPersonalChatGroupById(userId: string) {
    return this.http.get<PersonalChatGroupDto[]>(
      `${this.baseUrl}Chat/GetChatGroupsByUserId/${userId}`
    )
  }
  GetPagedPrivateChatHistory(
    userId: string,
    chatGroupId: number,
    pageNumber: number
  ) {
    return this.http.get<ChatHistoryDto>(
      `${this.baseUrl}Chat/GetPagedPrivateChatHistory?userId=${userId}&chatGroupId=${chatGroupId}&pageNumber=${pageNumber}&pageSize=1000`
    )
  }
  GetChatChannelsByUserId(userId: string) {
    return this.http.get<ChatChannelsDto>(
      `${this.baseUrl}Chat/GetChatChannelsByUserId/${userId}`
    )
  }

  SendMessage(body: SendMessageDto) {
    return this.http.post<ChatDto>(`${this.baseUrl}Chat`, body)
  }
  CreateChatGroup(data: CreateChatGroupDto) {
    return this.http.post<ChatDto>(`${this.baseUrl}Chat/CreateChatGroup`, data)
  }
  AddUserToChatGroup(data: AddUserToChatGroupDto) {
    return this.http.post<ChatDto>(
      `${this.baseUrl}Chat/AddUserToChatGroup`,
      data
    )
  }
  MarkChatViewed(data: MarkChatViewedDto) {
    return this.http.post<ChatDto>(`${this.baseUrl}Chat/MarkChatViewed`, data)
  }
  MarkChatGroupMute0rUnMute(data: MarkChatGroupMuteDto) {
    return this.http.post(
      `${this.baseUrl}Chat/MarkChatGroupMuteOrUnMute`,
      data,
      httpOptions
    )
  }
  deleteChatGroupById(id: number){
    return this.http.delete(
      `${this.baseUrl}Chat/DeleteChatGroupById/${id}`,
      httpOptions
    )
  }
}
