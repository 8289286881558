import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'shortCode',
  standalone: true,
  pure: true, // To optimize performance by skipping unnecessary computations when the input doesn't change.
})
export class ShortCodePipe implements PipeTransform {

  transform(value: string, ...args: unknown[]): unknown {
    if(value){
      const names = value.split(" ");
    if (names.length === 1) {
      // Case where only one name is provided
      return names[0].slice(0, 2).toUpperCase();
    } else if (names.length === 2) {
      // Case where first and last names are provided
      const firstName = names[0].charAt(0).toUpperCase();
      const lastName = names[1].charAt(0).toUpperCase();
      return firstName + lastName;
    } else {
      // Case where there are more than two names (middle names, etc.)
      const firstName = names[0].charAt(0).toUpperCase();
      const lastName = names[names.length - 1].charAt(0).toUpperCase();
      return firstName + lastName;
    }
    } else{
      return null;
    }
  }

}
