<div class="head-line bg-primary text-white">
  <a href="#" class="text-white font-14px text-decoration-none">LIMITED TIME OFFER: <b>SAVE UP TO 50% OFF THE LIFEMD GLP-1 WEIGHT MANAGEMENT PROGRAM</b></a>
</div>
<header class="navbar navbar-expand-lg fixed-top" style="margin-top: 30px;" [attr.data-bs-theme]="mode">  
  <div class="container">
    <component-logo-box [className]="'pe-sm-3'" />

    <app-theme-switcher class="form-check form-switch mode-switch order-lg-2 me-3 me-lg-4 ms-auto" />

    <a *ngIf="!currentUser?.id" class="btn btn-primary btn-sm fs-sm order-lg-3 d-none d-sm-inline-flex" rel="noopener"
      [routerLink]="['/services']" routerLinkActive="router-link-active">
      Get Started
    </a>
    <div *ngIf="currentUser?.id" class="dropdown nav d-none d-sm-block order-lg-3">
      <a class="nav-link d-flex align-items-center p-0" href="javascript:void(0);" data-bs-toggle="dropdown"
        aria-expanded="false">
        <img *ngIf="currentUser.profileImagePath" class="border rounded-circle"
          [src]="appConstants.awsBaseUrl+currentUser.profileImagePath" width="48" height="48"
          style="height: 48px;min-height: 48px;min-width: 48px;object-fit: cover;" alt="Isabella Bocouse" />
        <!-- <img *ngIf="!currentUser.profileImagePath"
          class="border rounded-circle"
          src="../../../../assets/img/custom/user-icon.png"
          width="48"
          alt="Isabella Bocouse"
        /> -->
        <div *ngIf="!currentUser.profileImagePath"
          class="d-flex align-items-center justify-content-center position-relative flex-shrink-0 rounded-circle text-primary fs-xl fw-bold my-1"
          style="width: 48px; height: 48px; background-color: rgba(var(--ar-primary-rgb), 0.15);">
          {{currentUser.fullName | shortCode}}
        </div>
        @if (totalUnreadCountOfChats > 0) {
        <span class="message-notification-count badge bg-danger ms-auto">{{
          totalUnreadCountOfChats
          }}</span>}
        <div class="ps-2">
          <div class="fs-xs lh-1 opacity-60">Hello,</div>
          <div class="fs-sm dropdown-toggle">{{currentUser.fullName}}</div>
        </div>
      </a>
      <div class="dropdown-menu dropdown-menu-end my-1">
        @if (securityService.securityObject.userType != userTypeEnum.AppAdmin) {
        <h6 class="dropdown-header fs-xs fw-medium text-body-secondary text-uppercase pb-1">
          Account
        </h6>
        <a class="dropdown-item" routerLink="/app/profile">
          <i class="ai-user-check fs-lg opacity-70 me-2"></i>
          Profile
        </a>
        <div class="dropdown-divider"></div>
        <h6 class="dropdown-header fs-xs fw-medium text-body-secondary text-uppercase pb-1">
          Dashboard
        </h6>
        <a class="dropdown-item" routerLink="/app/appointments">
          <i class="ai-calendar-check fs-lg opacity-70 me-2"></i>
          Appointments
        </a>
        <div class="dropdown-divider"></div>
        }
        <a class="dropdown-item" (click)="signOut()" style="cursor: pointer;">
          <i class="ai-logout fs-lg opacity-70 me-2"></i>
          Sign out
        </a>
      </div>
    </div>
    <vertical-menu-button></vertical-menu-button>

    <nav class="collapse navbar-collapse" id="navbarNav">
      <horizontal-app-menu />
    </nav>
  </div>
</header>